* {
  margin: 0;
  padding: 0;
}

.protected-report {
  height: 80vh;
}

.protected-report > iframe {
  border: 0 !important;
}
